import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
import InquiryForm from './inquiry.js';
window.InquiryForm = InquiryForm;
window.ParkingPage = function() {
    return {
        parking_container: document.getElementById('parking_container'),
        parking_template: document.getElementById('parking_template').value,
        init() {
            // console.log('ParkingPage: init', {
            //     parking_container: this.parking_container,
            //     parking_template: this.parking_template,
            // });
        },
        onOpenInquiry() {
            // console.log('onOpenInquiry');
            this.parking_container.classList.add('mb-[500px]');
        },
        onCloseInquiry() {
            // console.log('onCloseInquiry');
            this.parking_container.classList.remove('mb-[500px]');
        },
        checkoutButton(checkout_path, checkout_params) {
            let button = checkout_params.hasOwnProperty('button') ? checkout_params.button : null;
            // console.log(button, checkout_params);

            // Escrow.com checkout
            if (button === 'escrow_buy_button') {
                // submit the Escrow.com form to initiate transaction
                document.querySelector('#escrow-com-form').submit();

            // Dan.com BIN Checkout
            } else if (button === 'dan_buy_button') {
                // show loading spinner
                document.querySelector('#loading-backdrop').classList.remove('hidden');

                let backup_url = checkout_params.hasOwnProperty('backup_url') ? checkout_params.backup_url : null;
                // create Dan transaction and get back checkout URL
                axios.post(checkout_path, {'checkout_params': checkout_params})
                    .then(response => {
                        if (response.data.hasOwnProperty('checkout_url')) {
                            window.location.href = response.data.checkout_url;
                            // window.open(response.data.checkout_url, "_blank");
                            // document.querySelector('#loading-backdrop').classList.add('hidden');
                        } else {
                            // redirect to regular Dan lander as backup
                            // alert('no checkout url');
                            if (backup_url) window.location.href = backup_url;
                        }
                    })
                    .catch(error => {
                        // redirect to regular Dan lander as backup
                        // alert('checkout error');
                        if (backup_url) window.location.href = backup_url;
                    });
            }
        }
    }
}
